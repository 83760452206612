import React from 'react';
import './style.css';

const SectionTitleArea = ({ title, text }) => {
  return (
    <div className="section-title-area">
      <div className="section-title">
        <h2>{title}</h2>
      </div>
      <div className="title-dash">
      </div>
      <div className="section-text">
        <p>{text}</p>
      </div>
    </div>
  )
}

export default SectionTitleArea;
