import React from 'react';
import './style.css';

const SmallIconBox = ({icon, text, label}) => {
  return (
    <div className="small-icon-box">
      {icon}
      <div className="small-icon-box-label">
        <p>{label}</p>
      </div>
      <div className="small-icon-box-text">
        <p>{text}</p>
      </div>
    </div>
  );
}

export default SmallIconBox;
