import React from 'react';
import LightbulbMinimalIcon from '../Icons/LightbulbMinimalIcon';
import PlierIcon from '../Icons/PlierIcon';
import ManWrenchIcon from '../Icons/ManWrenchIcon';
import MriIcon from '../Icons/MriIcon';
import MaintenanceIcon from '../Icons/MaintenanceIcon';
import SmallIconBox from '../SmallIconBox';
import StandardSectionWrapper from '../StandardSectionWrapper';
import './style.css';
import ThinkIcon from '../Icons/ThinkIcon';
import Button from '../Button';

const globalFill = '#777';
const globalWidth = '45';
const wrapperWidth = '65';

const iconBoxData = [
  {
    icon: <PlierIcon wrapperWidth={wrapperWidth} width={globalWidth} fill={globalFill} />,
    label: 'Prepravka RF kabine',
    text: 'Ukoliko vršite građevinske izmene na objektu ili želite da zamenite MRI opremu možemo da Vam ponudimo prepravku RF kabine bez obzira na proizvođača.'
  },
  {
    icon:
      <ManWrenchIcon
        wrapperWidth={wrapperWidth} width={globalWidth} fill={globalFill} />,
    label: 'Popravka RF kabine',
    text: 'U mogućnosti smo da saniramo sva oštećenja na RF kabini uključujući i vrata i prozor.'
  },
  {
    icon: <MaintenanceIcon wrapperWidth={wrapperWidth} width={globalWidth} fill={globalFill} />, label: 'Održavanje',
    text: 'Održavanje obuhvata čišćenje dihtunga na vratima, periodičnu zamenu dihtunga, čišćenje ventilacionih filtera.'
  },
  {
    icon: <LightbulbMinimalIcon wrapperWidth={wrapperWidth} width={globalWidth} fill={globalFill} />,
    label: 'Rasveta',
    text: 'Nudimo usluge kompletnih rešenja za rasvetu, od električnih instalacija do ugradnje LED panela.'
  },
  {
    icon: <ThinkIcon wrapperWidth={wrapperWidth} width={globalWidth} fill={globalFill} />, label: 'Idejna rešenja prostora za MRI',
    text: 'U dosadašnjem radu smo realizovali nekoliko netipičnih postavki koje besprekorno funkcionišu već godinama.'
  },
  {
    icon: <MriIcon wrapperWidth={wrapperWidth} width={globalWidth} fill={globalFill} />, label: 'Kompletna adaptacija prostora za MRI',
    text: 'Osim izrade i ugradnje RF sobe nudimo uslugu kompletne adaptacije prostora za Magnetnu rezonancu. Na taj način sve brige oko sporednih radova prepuštate nama.'
  },
];

const SmallIconBoxesSection = () => {
  return (
    <StandardSectionWrapper hasTitle={true} title='Usluge' >
      <div className="small-icon-boxes-wrapper">
        {
          iconBoxData.map(el => (
            <SmallIconBox icon={el.icon} text={el.text} label={el.label} />
          ))
        }
      </div>
      <div className="services-button-fix" style={{ textAlign: 'center !important', marginTop: '30px' }}>
        <p style={{
          textAlign: 'center',
          margin: '34px auto 20px auto',
          fontSize: '18px',
          maxWidth: '280px'
        }}
        >
          Kontaktirajte nas za više detalja</p>
        <Button size='medium' link='/contact' text='Kontakt' />
      </div>
    </StandardSectionWrapper>
  )
}

export default SmallIconBoxesSection;
