import React from 'react';
import SectionTitleArea from '../SectionTitleArea';
import './style.css';

const StandardSectionWrapper = ({ hasTitle, background, children, title, hasStripe, hasBorder }) => {
  return (
    <section style={{ backgroundColor: `${background}` }} className={`standard-section ${hasBorder ? 'border' : ''}`}>
      <div className="standard-section-inner-wrapper container">
        {
          hasTitle &&
          <SectionTitleArea title={title} />
        }
        {children}
      </div>

      {
        hasStripe && (
          <div className="stripe">

          </div>
        )
      }
    </section>
  )
}

export default StandardSectionWrapper;
