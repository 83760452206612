import React from 'react';
import './style.css';
import { Link } from "gatsby";

const Button = ({ text, link, size, multiButton }) => {
  return (
    <button
      className={`standard-button size-${size} ${multiButton ? 'multi-button' : ''}`}
    >
      <Link to={link}>
        {text}
      </Link>
    </button>
  )
}

export default Button;
