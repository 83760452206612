import React from 'react';
import '../style.css';

const MaintenanceIcon = ({ fill, width, marginBottom, wrapperWidth }) => {
  return (
    <div style={{ width: `${wrapperWidth}px`, height: `${wrapperWidth}px`, marginBottom: `${marginBottom}px` }} className="icon-wrapper">
      <svg style={{ width: `${width}px` }} version="1.1" id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px" y="0px" viewBox="0 0 122.88 112.14"
      >
        <g>
          <path style={{ stroke: `${fill}`, fill: '#fff', strokeWidth: '5' }} class="st1" d="M101.58,14.7l8.82,8.82c2.32,2.32,2.32,6.12,0,8.44l-7.1,7.1c1.96,3.66,3.45,7.6,4.41,11.75h9.2 c3.28,0,5.97,2.69,5.97,5.97v12.47c0,3.28-2.69,5.97-5.97,5.97h-10.04c-1.23,4.06-2.99,7.9-5.2,11.42l6.51,6.51 c2.32,2.32,2.32,6.12,0,8.44l-8.82,8.82c-2.32,2.32-6.12,2.32-8.44,0L84,103.48V88.03c7.53-6.4,12.31-15.94,12.31-26.59 c0-19.26-15.62-34.88-34.88-34.88l0,0l0,0c-19.26,0-34.88,15.62-34.88,34.88c0,9.64,3.92,18.38,10.25,24.69l-0.57,15.54l-6.51,6.51 c-2.32,2.32-6.12,2.32-8.44,0l-8.82-8.82c-2.32-2.32-2.32-6.12,0-8.44l7.1-7.1c-1.96-3.66-3.46-7.6-4.41-11.75h-9.2 C2.69,72.06,0,69.37,0,66.09V53.63c0-3.28,2.69-5.97,5.97-5.97h10.04c1.23-4.06,2.99-7.9,5.2-11.42l-6.51-6.51 c-2.32-2.32-2.32-6.12,0-8.44l8.82-8.82c2.32-2.32,6.12-2.32,8.44,0l7.1,7.1c3.66-1.96,7.6-3.46,11.75-4.41v-9.2 C50.81,2.69,53.5,0,56.78,0h12.47c3.28,0,5.97,2.69,5.97,5.97V16c4.06,1.23,7.9,2.99,11.42,5.2l6.51-6.51 C95.46,12.38,99.26,12.38,101.58,14.7L101.58,14.7L101.58,14.7L101.58,14.7z" />
          <path style={{ fill: `${fill}` }} class="st0" d="M44.23,74.52c-4.34-5.72-7.79-12.14-4.2-21.56c2.32-6.11,7.68-11.12,14.43-13.92 C43.43,63.38,75.1,72.1,76.05,43.27c10.6,8.8,10.51,21.78,2.74,32.27c-3.67,4.96-4.66,7.75-5.46,10.6 c-0.46,1.56-0.55,3.13-0.55,5.11c-0.01,2.69,0.11,12.49,0.26,20.68h-7.21V90.56c0-3.87-5.85-3.87-5.85,0v21.37h-6.63 c-0.15-7.67-0.31-16.92-0.48-19.35c-0.15-2.5-0.4-4.17-1.02-5.75c-1.09-2.86-2.47-5.45-7.62-12.22L44.23,74.52L44.23,74.52 L44.23,74.52z" />
        </g>
      </svg>
    </div>
  );
}

export default MaintenanceIcon;
